import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Wrapper from '../components/wrapper';

const RunningPage = () => (
  <Layout>
    <SEO title="Marathon Runner and Triathlete" />
    <Wrapper>
      <p>
        I enjoy running and triathlons and I like to race once or twice a year.
        I've raced distances from 5K (3.1 miles) to the Ironman (140.6 miles).
        It's my dream to run the Boston Marathon and Ironman Hawaii. I use{' '}
        <a href="https://www.athlinks.com/athletes/115130180">Athlinks</a> to
        track all of my races and{' '}
        <a href="https://www.strava.com/athletes/6508690">Stava</a> for my
        day-to-day training.
      </p>
    </Wrapper>
  </Layout>
);

export default RunningPage;
